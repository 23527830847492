import React from 'react'
import Layout from '../components/Layout'
import Page from '../components/Page'
import Block from '../components/primitives/Block'

const VulnerabilityDisclosure = () => (
  <Layout justifyCenter title="Vulnerability Disclosure Policy">
    <Page>
      <Block maxWidth="mediumBlock">
        <p>
          At Swarmia, we take the security seriously. We appreciate the efforts
          of security researchers in helping us maintain a secure environment
          for our customers.
        </p>

        <h2>Scope</h2>
        <ul>
          <li>Swarmia&apos;s primary application domain at app.swarmia.com</li>
          <li>All production infrastructure and services</li>
        </ul>

        <h2>Submission Guidelines</h2>
        <p>
          Please report security vulnerabilities by emailing{' '}
          <a href="mailto:security@swarmia.com">security@swarmia.com</a>.
          Include:
        </p>
        <ul>
          <li>Clear description of the vulnerability</li>
          <li>Steps to reproduce</li>
          <li>Potential impact</li>
          <li>Any relevant screenshots or proof of concept</li>
        </ul>

        <h2>Out of Scope</h2>
        <ul>
          <li>Denial of Service attacks</li>
          <li>Physical security attacks</li>
          <li>Social engineering attacks</li>
          <li>Third-party services/applications</li>
          <li>Findings from automated vulnerability scanners</li>
        </ul>

        <h2>Rewards</h2>
        <p>
          Swarmia offers rewards for identified and validated security
          vulnerabilities. The reward amount is determined by our security team
          based on:
        </p>
        <ul>
          <li>Severity of the vulnerability</li>
          <li>Quality of the report</li>
          <li>Potential impact on our systems and users</li>
          <li>Complexity of exploitation</li>
        </ul>

        <p>
          All rewards are subject to the final review and approval of
          Swarmia&apos;s security team.
        </p>

        <p>
          For questions about this policy, contact{' '}
          <a href="mailto:security@swarmia.com">security@swarmia.com</a>.
        </p>
      </Block>
    </Page>
  </Layout>
)

export default VulnerabilityDisclosure
